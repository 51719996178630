import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataStorageService } from 'app/services/data.service';
import { catchError, switchMap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class AccountService {

    myClientAccounts = environment.ENDPOINTS.MY_CLIENT_ACCOUNTS_URL;

    /**
     * Constructor
     *
     * @param httpClient http client
     */
    constructor(private httpClient: HttpClient, private cacheStorageService: DataStorageService) {
    }

    /**
     * get clients accounts
     */
    getAccountsClients(): Observable<any> {
        const req = this.myClientAccounts;
        // return this.httpClient.get<any>(req);
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(req)).pipe(
                catchError(() => this.httpClient.get<any>(req))
            );
        }
        return this.httpClient.get<any>(req).pipe(
            switchMap(response => {
                this.cacheStorageService.setItem(req, response);
                return of(response);
            })
        );
    }

    getAccountClientById(id): Observable<any> {
        const req = this.myClientAccounts + `/${id}`;
        // return this.httpClient.get<any>(req);
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(req)).pipe(
                catchError(() => this.httpClient.get<any>(req))
            );
        }
        return this.httpClient.get<any>(req).pipe(
            switchMap(response => {
                this.cacheStorageService.setItem(req, response);
                return of(response);
            })
        );
    }
}


